<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'crm-customer-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna ai Clienti
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato">
        <b-card>
          <b-card-body class="px-0 py-0">
              <b-tabs pills>
                <b-tab active>
                  <template #title>
                      <feather-icon
                      icon="UserIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Anagrafica</span>
                  </template>
                  <b-card bg-variant="Default">
                      <b-row>
                        <b-col md="4" lg="3">
                          <showdata field_name="Ragione Sociale" :field_value="campiform.billing.company_name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Nome" :field_value="campiform.user.name" />
                        </b-col>
                        <b-col md="8" lg="3">
                          <showdata field_name="Cognome" :field_value="campiform.user.surname" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Email" :field_value="campiform.user.email" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Telefono" :field_value="campiform.user.phone" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Cellulare" :field_value="campiform.user.mobile_phone" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Reseller" :field_value="campiform.customer.nome_assistente" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Assistente" :field_value="campiform.customer.nome_reseller" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Sito web" :field_value="campiform.user.website" />
                        </b-col>
                      </b-row>

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="BriefcaseIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Fatturazione</span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-row>
                        <b-col md="4" lg="3">
                          <showdata field_name="Indirizzo" :field_value="campiform.billing.address" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Cap" :field_value="campiform.billing.zip" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Città" :field_value="campiform.billing.city" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <div v-if="campiform.billing.province_name == 'Estera'">
                              <showdata field_name="Provincia" :field_value="campiform.billing.province_foreign" />
                            </div>
                            <div v-else>
                              <showdata field_name="Provincia" :field_value="campiform.billing.province_name" />
                            </div>
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Nazione" :field_value="campiform.billing.country_name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Partita IVA" :field_value="campiform.billing.vat_number" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Codice fiscale" :field_value="campiform.billing.fiscal_code" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Pec" :field_value="campiform.billing.pec" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Codice SDI" :field_value="campiform.billing.dest_code" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Regime IVA" :field_value="campiform.billing.vat_scheme_name" />
                        </b-col>
                        <b-col md="4" lg="6">
                          <showdata field_name="Pagamenti Accettati" :field_value="formattazioneArray(campiform.customer.payment)" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Split Payment" :field_value="campiform.billing.split_payment" />
                        </b-col>
                        <b-col md="8" lg="9">
                          <showdata field_name="Note XML" :field_value="campiform.billing.note_xml" />
                        </b-col>
                      </b-row>

                    </b-card>
                  </b-tab>

              </b-tabs>
          </b-card-body>
        </b-card>
    </div>
      
  </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BFormGroup, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, BBadge, BFormTextarea, BPagination, BFormRadioGroup, BFormValidFeedback, BOverlay, VBTooltip, BTable, BModal } from 'bootstrap-vue'

import showdata from '@core/components/showdata/showdata.vue'
import { prezzoFormattato } from '@core/utils/funzioniGlobali' 
import moment from 'moment'
import store from '@/store/index'
import { logoutGeneral } from "@core/components/logout/logout.vue"

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner,
    BFormGroup,
    BForm, 
    BInputGroup, 
    BFormInput, 
    BInputGroupPrepend, 
    BInputGroupAppend, 
    BDropdownDivider,
    BFormSelect, 
    BDropdown, 
    BDropdownItem, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,
    BFormTextarea,
    BPagination, 
    BFormRadioGroup,
    BFormValidFeedback,
    BOverlay,
    BTable, 
    BModal, 

    showdata,

    logoutGeneral,
  },
  setup() {
    return {
      prezzoFormattato,
    }
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: {},
      
      id_italina: '1',
      campiform: {
        customer: {
          id: '',
          user_id: '',
          reseller_id: '',
          reseller_name: '',
          assistant_id: '',
          assistant_name: '',
          hr_id: '',
          evaluation_id: '',
          evaluation_name: '',
          created_at: '',
          source: [],
          note: '',
          lead_registered_at: '',
          section: '',
          payment: [ ],
        },
        user: {
          email: '', 
          name: '', 
          surname: '',
          mobile_phone: '',
          phone: '',
          website: '',
          newsletter: '',
        },
        billing: {
          country_id: '',
          country_name: '',
          province_id: '',
          province_name: '',
          province_foreign: '', 
          address: '',
          zip: '',
          city: '',
          company_name: '',
          vat_number: '',
          fiscal_code: '',
          pec: '',
          dest_code: '',
          vat_scheme_id: null,
          vat_scheme_name: '',
          note_xml: '', 
          split_payment: '',
        },
      },
      sidebar_visibile: false,
      options: [ ],
      options_province: [ ],
      options_regine_iva: [ ],
      Caricato: false,
      errore_caricamento: false,
      
      //dir: false,
      
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        2 : 'light-dark',
        3 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    statusVariantEvaluation() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        2 : 'light-warning',
        3 : 'light-dark',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))

    console.log(this.userData)

    this.$http.get('api/customer/user/singolacard/'+this.userData.id).then(response_userdati => {
      if((response_userdati.data.statusCode>=200) && (response_userdati.data.statusCode<=299)){
        //caricamento dati utente
        this.campiform.user = response_userdati.data.reply.data
        
        const countryPromise = this.$http.get('api/fs/country/list')
        const provincePromise = this.$http.get('api/fs/province/list')
        const vatSchemePromise = this.$http.get('api/fs/employee/vat-scheme/list')
        const infoBillingPromise = this.$http.get('api/customer/user/customerbillingcard/'+this.userData.id)

        Promise.all([countryPromise, provincePromise, vatSchemePromise, infoBillingPromise]).then(response => {
        if(response[0].data.statusCode===200){
          //caricamento lista nazioni
          this.options = response[0].data.reply

          if(response[1].data.statusCode===200){
            //caricamento lista province italiane
            this.options_province = response[1].data.reply
                  
            if(response[2].data.statusCode===200){
              //caricamento lista regime iva
              this.options_regine_iva = response[2].data.reply  
              
              if(response[3].data.statusCode===200){
                //dati billing (customer) + modalità di pagamento
                this.campiform.billing = response[3].data.reply.billing  
                this.campiform.customer = response[3].data.reply.customer         
                this.campiform.customer.payment = response[3].data.reply.payment

                //nazione
                var id_nazione = this.campiform.billing.country_id
                var nazione = this.options.filter(function(elem){
                    if(elem.value == id_nazione){
                    return elem.text
                    }
                })
                this.campiform.billing.country_name = nazione[0].text

                //provincia
                var id_provincia = this.campiform.billing.province_id
                var provincia = this.options_province.filter(function(elem){
                    if(elem.value == id_provincia){
                      return elem.text
                    }
                })
                this.campiform.billing.province_name = provincia[0].text

                //regime iva
                var id_regime_iva = this.campiform.billing.vat_scheme_id
                var regime_iva = this.options_regine_iva.filter(function(elem){
                    if(elem.value == id_regime_iva){
                      return elem.text
                    }
                })
                this.campiform.billing.vat_scheme_name = regime_iva[0].text

                this.Caricato = true;
                this.errore_caricamento = false;    

              } else {
              this.Caricato = false;
              this.errore_caricamento = true;
              }
            } else {
            this.Caricato = false;
            this.errore_caricamento = true;
            } 
            
          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      })


      } else {
        //risposta negativa (errore sul server)
        this.Caricato = false;
        this.errore_caricamento = true;
      }
    }).catch(e => {
      console.log(e);
    });
    
  },
  methods: {
    formattazioneArray(ArrayPassato) {
      return ArrayPassato.map(obj => obj.name).join(', ');
    },
    formattazioneDataOra(created_at,lang) {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
      } else {
        return '---'
      }
    },

  },
  /*
  formattazioneDataOra(created_at,lang) {
    if (created_at) {
      return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
    } else {
      return '---'
    }
  },
  */
  
}
</script>
